import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import { PositionEditorIcon, DivisionsIcon, GeographicalStructure, WorkAndTasksIcon, WorkplacesIcon } from "@icarius-icons/";

const LocationSettingsBox = () => {

  const data = [
    {
      name: "Lugares de trabajo",
      icon: <WorkplacesIcon />,
      path: paths.locationSettings,
    },
    {
      name: "Obras y Faenas",
      icon: <WorkAndTasksIcon />,
      path: paths.worksAndTasksSettings,
    },
    {
      name: "Estructura departamental",
      icon: <PositionEditorIcon />,
      path: paths.organizationStructure,
    },
    {
      name: "Organización geográfica",
      icon: <GeographicalStructure />,
      path: paths.geographicalStructure,
    },
    {
      name: "Unidades de negocio",
      icon: <DivisionsIcon />,
      path: paths.divisionsSettings,
    },
  ];

  return (
    <ScreenSelector
      title={getLocalizedString("locationSettingsBox")}
      data={data}
    />
  );
}

export default LocationSettingsBox;
