import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createLocationSettingsAction, updateLocationSettingsAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import MapWithASearchBox from "./map";
import PlayButton from "@icarius-common/audioPlayer/components/playButton";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const {
    data,
    types,
    holidayTypes,
    handleClose,
    country,
  } = props;

  const dispatch = useDispatch();

  const [mapIsOpen, setMapIsOpen] = useState(false);

  const create = (dataToSend) => {
    dispatch(createLocationSettingsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updateLocationSettingsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  const handleSetMapData = (data) => {
    setFormValue(data.name, "address");
    setFormValue(`${data.lat},${data.long}`, "coordinates");
  }

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} style={{ justifyContent: "center", gap: 10 }}>
          <Button variant="outlined" onClick={() => setMapIsOpen(prev => !prev)} style={{ minWidth: 200 }}>
            {mapIsOpen ? "Cerrar mapa" : "Abrir mapa"}
          </Button>
          <PlayButton audioName="Abrir mapa (Latitud y Longitud)" title="Abrir mapa (Latitud y Longitud)" />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={2} style={gridStyle}> {/* codigo */}
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}
            label={"Código"}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 8 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={5} style={gridStyle}> {/* nombre */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={"Nombre del lugar de trabajo"}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={5} style={gridStyle}> {/* dirección */}
          <TextField
            required={formData.code !== "-"}
            fullWidth
            margin={"none"}
            label={"Dirección"}
            value={formData.address}
            onChange={(e) => setFormValue(e.target.value, "address")}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* zip code */}
          <TextField
            fullWidth
            margin={"none"}
            label={"Código postal"}
            value={formData.zipCode}
            onChange={(e) => setFormValue(e.target.value, "zipCode")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={6} style={gridStyle}> {/* coordenadas */}
          <TextField
            fullWidth
            margin={"none"}
            label={"Latitud y longitud"}
            value={formData.coordinates}
            onChange={(e) => setFormValue(e.target.value, "coordinates")}
            inputProps={{ maxLength: 41 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* range */}
          <div style={{ display: "flex", alignItems: 'center', width: "100%", gap: 10 }}>
            <TextField
              required={Boolean(formData.coordinates)}
              fullWidth
              margin={"none"}
              label={"Rango permitido para marcar asistencia"}
              value={formData.range}
              onChange={(e) => setFormValue(e.target.value, "range")}
            />
            <PlayButton audioName="Rango permitido desde la geoposición" title="Rango permitido desde la geoposición" />
          </div>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* tipo */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-type-${data ? data.internalCode : "new"}`}>{"Tipo"}</InputLabel>
            <Select
              value={formData.type}
              labelId={`label-type-${data ? data.internalCode : "new"}`}
              id={`select-type-${data ? data.internalCode : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "type")}
              margin={"none"}
            >
              {
                types && types.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        {
          country === "CL" &&
          <>
            <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* commune */}
              <TextField
                fullWidth
                margin={"none"}
                label={"Comuna"}
                value={formData.commune}
                onChange={(e) => setFormValue(e.target.value, "commune")}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* realEstateRole */}
              <TextField
                fullWidth
                margin={"none"}
                label={"Rol avalúo bien raíz"}
                value={formData.realEstateRole}
                onChange={(e) => setFormValue(e.target.value, "realEstateRole")}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* patentRole */}
              <TextField
                fullWidth
                margin={"none"}
                label={"Rol patente municipal"}
                value={formData.patentRole}
                onChange={(e) => setFormValue(e.target.value, "patentRole")}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>
          </>
        }
      </Grid>
      <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* tipo de feriado */}
        <FormControl style={{ width: "100%" }}>
          <InputLabel required id={`label-holidayType-${data ? data.internalCode : "new"}`}>{"Tipo de feriados"}</InputLabel>
          <Select
            value={formData.holidayType}
            labelId={`label-holidayType-${data ? data.internalCode : "new"}`}
            id={`select-holidayType-${data ? data.internalCode : "new"}`}
            onChange={(e) => setFormValue(e.target.value, "holidayType")}
            margin={"none"}
          >
            {
              holidayTypes && holidayTypes.map(item => (
                <MenuItem
                  className={"whiteText"}
                  key={item.key}
                  value={item.key}>
                  {item.value}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
      {
        mapIsOpen &&
        <Grid container item alignItems="center" xs={12} style={{ paddingTop: 20, paddingBottom: 30 }}>
          <MapWithASearchBox
            open={mapIsOpen}
            handleSetData={handleSetMapData}
          />
        </Grid>
      }
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default Form;