import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.loansDetailEmployer],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "CODIGO DE EMPLEADO",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.loansDetailEmployer],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "APELLIDO Y NOMBRE",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.loansDetailEmployer],
        config: {
            headerName: getLocalizedString("loanType2"),
            field: "Tipo de préstamo",
            chartDataType: "category",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.loansDetailEmployer],
        config: {
            headerName: getLocalizedString("referenceCode"),
            field: "Código de Referencia",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.loansDetailEmployer],
        config: {
            headerName: getLocalizedString("coin"),
            field: "MONEDA",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.loansDetailEmployer],
        config: {
            headerName: getLocalizedString("grantedValueLoan"),
            field: "Valor del préstamo otorgado",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.loansDetailEmployer],
        config: {
            headerName: getLocalizedString("installmentNumber"),
            field: "N° de cuota",
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.loansDetailEmployer],
        config: {
            headerName: getLocalizedString("installmentState"),
            field: "Estado de la cuota",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.loansDetailEmployer],
        config: {
            headerName: getLocalizedString("periodDiscount"),
            field: "Período de descuento",
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.loansDetailEmployer],
        config: {
            headerName: getLocalizedString("installmentValue"),
            field: "Valor de la cuota",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.loans],
        config: {
            headerName: getLocalizedString("loanType2"),
            field: "Tipo de préstamo",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
        }
    },
    {
        pages: [paths.loans],
        config: {
            headerName: getLocalizedString("coin"),
            field: "MONEDA",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.loans],
        config: {
            headerName: getLocalizedString("numberLoansGranted"),
            field: "Cantidad de préstamos otorgados",
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.loans],
        config: {
            headerName: getLocalizedString("totalValueLoansGranted"),
            field: "Valor total préstamos otorgados",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.loans],
        config: {
            headerName: getLocalizedString("totalPaid"),
            field: "Total pagado",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.loans],
        config: {
            headerName: getLocalizedString("totalPendingBalance"),
            field: "Total saldo pendiente",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.loans],
        config: {
            headerName: getLocalizedString("totalInstallments"),
            field: "Total cuotas",
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.loans],
        config: {
            headerName: getLocalizedString("totalPaidInstallments"),
            field: "Total cuotas pagadas",
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            chartDataType: "series",
            enableValue: true,
        }
    }, {
        pages: [paths.loans],
        config: {
            headerName: getLocalizedString("totalPendingInstallments"),
            field: "Total cuotas pendientes",
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.loansDetailCollaborator],
        config: {
            headerName: getLocalizedString("loanType2"),
            field: "Tipo de préstamo",
            chartDataType: "category",
            enableRowGroup: true,
            enableValue: true,
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.loansDetailCollaborator],
        config: {
            headerName: getLocalizedString("referenceCode"),
            field: "Código de Referencia",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.loansDetailCollaborator],
        config: {
            headerName: getLocalizedString("coin"),
            field: "MONEDA",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.loansDetailCollaborator],
        config: {
            headerName: getLocalizedString("installmentNumber"),
            field: "N° de cuota",
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.loansDetailCollaborator],
        config: {
            headerName: getLocalizedString("installmentState"),
            field: "Estado de la cuota",
            filter: "agSetColumnFilter",
            enableRowGroup: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.loansDetailCollaborator],
        config: {
            headerName: getLocalizedString("periodDiscount"),
            field: "Período de descuento",
            filter: "agTextColumnFilter",
            enableRowGroup: true,
            chartDataType: "series",
            enableValue: true,
        }
    },
    {
        pages: [paths.loansDetailCollaborator],
        config: {
            headerName: getLocalizedString("installmentValue"),
            field: "Valor de la cuota",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
]