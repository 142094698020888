import _ from 'lodash';
import React, { useEffect } from "react";
import { compose, withProps, lifecycle } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";
import { GOOGLE_MAPS_KEY } from '@icarius-utils/properties';

const MapWithASearchBox = compose(
    withProps({
        googleMapURL:
            `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&v=3.exp&language=es&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `500px`, width: "100%" }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    lifecycle({
        componentDidMount() {
            const refs = {}

            this.setState({
                data: {
                    name: "",
                    lat: "",
                    long: "",
                },
                bounds: null,
                center: {
                    lat: 41.9, lng: -87.624
                },
                markers: [],
                onMapMounted: ref => {
                    refs.map = ref;
                },
                onBoundsChanged: () => {
                    this.setState({
                        bounds: refs.map.getBounds(),
                        center: refs.map.getCenter(),
                    })
                },
                onSearchBoxMounted: ref => {
                    refs.searchBox = ref;
                },
                onPlacesChanged: () => {
                    const places = refs.searchBox.getPlaces();
                    const bounds = new window.google.maps.LatLngBounds();

                    places.forEach(place => {
                        if (place.geometry.viewport) {
                            bounds.union(place.geometry.viewport)
                        } else {
                            bounds.extend(place.geometry.location)
                        }
                    });
                    const nextMarkers = places.map(place => ({
                        position: place.geometry.location,
                    }));
                    const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

                    this.setState({
                        center: nextCenter,
                        markers: nextMarkers,
                        data: {
                            name: places.length > 0 ? places[0].formatted_address : "",
                            lat: places.length > 0 ? places[0].geometry.location.lat() : "",
                            long: places.length > 0 ? places[0].geometry.location.lng() : "",
                        }
                    });
                },
            })
        },
    }),
    withScriptjs,
    withGoogleMap
)
    (props => {
        const { data, handleSetData } = props;

        useEffect(() => {
            if (data?.name && data?.lat && data?.long) {
                handleSetData(data);
            }
        }, [data, handleSetData])

        return (
            <GoogleMap
                ref={props.onMapMounted}
                defaultZoom={15}
                center={props.center}
                onBoundsChanged={props.onBoundsChanged}
            >
                <SearchBox
                    ref={props.onSearchBoxMounted}
                    bounds={props.bounds}
                    controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
                    onPlacesChanged={props.onPlacesChanged}
                >
                    <input
                        type="text"
                        placeholder="Ingrese ubicación para obtener coordenadas"
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            minWidth: "100px",
                            maxWidth: "calc(100vw - 300px)",
                            width: `70%`,
                            height: `38px`,
                            marginTop: `10px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `18px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                        }}
                    />
                </SearchBox>
                {
                    props.markers.map((marker, index) =>
                        <Marker key={index} position={marker.position} />
                    )
                }
            </GoogleMap>
        )
    }
    );

export default MapWithASearchBox;