import { useState } from "react";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const formatCoordinates = () => {    
    if(data.latitude && data.longitude) return `${data.latitude},${data.longitude}`;
    if(data.latitude && !data.longitude) return `${data.latitude}`;
    if(!data.latitude && data.longitude) return `${data.longitude}`;
    if(!data.latitude && !data.longitude) return "";
  }

  const [ code, setCode ] = useState(isCreate ? "" : data.code); 
  const [ name, setName ] = useState(isCreate ? "" : data.name);  
  const [ address, setAddress ] = useState(isCreate ? "" : data.address);  
  const [ zipCode, setZipCode ] = useState(isCreate ? "" : data.zipCode);  
  const [ coordinates, setCoordinates ] = useState(isCreate ? "" : formatCoordinates());
  const [ range, setRange ] = useState(isCreate ? "" : data.range);  
  const [ type, setType ] = useState(isCreate ? "" : data.type);
  const [ holidayType, setHolidayType ] = useState(isCreate ? "1" : data.holidayType);
  const [ commune, setCommune ] = useState(isCreate ? "" : data.commune);
  const [ realEstateRole, setRealEstateRole ] = useState(isCreate ? "" : data.realEstateRole);
  const [ patentRole, setPatentRole ] = useState(isCreate ? "" : data.patentRole);
  
  const formData = {
    code,
    name,
    address,
    zipCode,
    coordinates,
    range,
    type,
    commune,
    realEstateRole,
    patentRole,
    holidayType,
  }

  const dataIsValid = () => {
    if(!code || code === "") return false;
    if(!holidayType || holidayType === "") return false;
    if(!name || name === "") return false;
    if(!address || address === ""){
      if(code !== "-"){
        return false; //si no hay direccion pero el code es "-", esta todo piola
      }
    }
    if((coordinates && coordinates !== "") && (range === "")) return false;    
    return true;
  }

  const submit = () => {
    if(dataIsValid()){
      const coordinatesArr = coordinates.split(",");
      let dataToSend = { ...formData, latitude: coordinatesArr[0], longitude: coordinatesArr[1] };
      delete dataToSend.coordinates;

      if(!isCreate){
        dataToSend = { ...dataToSend, internalCode: data.internalCode };
      }

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    }else{
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    switch(fieldName){
      case "code":
        setCode(value?.length > 0 ? value.toUpperCase().replace(/\s/g, "") : "");
        break; 
      case "name":
        setName(value);
        break;    
      case "address":
        setAddress(value);
        break;  
      case "zipCode":
        setZipCode(value);
        break;  
      case "coordinates":
        setCoordinates(value?.length > 0 ? value.replace(/ /g,'') : "");
        break;  
      case "range":
        setRange(value?.length > 0 ? value.replace(/\D/g,'').substring(0, 4) : "");
        break;  
      case "type":
        setType(value);
        break;  
      case "commune":
        setCommune(value);
        break;  
      case "realEstateRole":
        setRealEstateRole(value);
        break;  
      case "patentRole":
        setPatentRole(value);
        break; 
      case "holidayType":
        setHolidayType(value);
        break; 
      default: break;
    }
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
