import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CloseIcon } from "@icarius-icons";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import paths from "@icarius-localization/paths";

const StyledDialog = withStyles({
  paper: {
    maxHeight: "95%",
    minHeight: "85%",
    maxWidth: "95%",
    minWidth: "85%",
  },
})(Dialog);

const SecondGridDialog = (props) => {

  const {
    open,
    data,
    type,
    coin,
    level,
    dateFormat,
    locale,
    handleClose,
  } = props;

  return (
    <StyledDialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      scroll={"paper"}
      maxWidth={"md"}
      fullWidth
    >
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {coin === "-" ? `${type}` : `${type} - ${coin}`}
      </DialogTitle>
      <DialogContent>
        <CommonPage
          gridTitle={getLocalizedString("loansTitleLong")}
          columnDefPage={
            level === "C"
              ? paths.loansDetailCollaborator
              : paths.loansDetailEmployer
          }
          rowData={data}
          dateFormat={dateFormat}
          locale={locale}
          gridHeight={'70vh'}
          ignoreProcessesbyPath
          hasSelectAll
        />
      </DialogContent>
    </StyledDialog>
  );
}

export default SecondGridDialog;