import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsExportingGrid } from "src/app/selectors";
import { getTheme } from "@icarius-pages/login/selectors";
import { getAppColor, getUserData } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { getLoansRowsFromServer, getLoansDetailRowsFromServer } from "../actions";
import { getLoansRows, getLoansRowsByTypeAndCurrency, loading, getDateFormat, getLocale } from "../selectors";
import SecondGridDialog from "./secondGridDialog";
import GraphsDialog from "./graphsDialog";

const Loans = ({ employeeCode, employeeName, handleGoBack }) => {

  const [graphsDialogIsOpen, setGraphsDialogIsOpen] = useState(false);
  const [secondGridDialogIsOpen, setSecondGridDialogIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dataForCharts, setDataForCharts] = useState(null);

  const dispatch = useDispatch();
  const exportingGridStatus = useSelector(getIsExportingGrid);
  const loansRows = useSelector(getLoansRows);
  const loansRowsByTypeAndCurrency = useSelector(getLoansRowsByTypeAndCurrency);
  const loadingRows = useSelector(loading);
  const color = useSelector(getAppColor);
  const dateFormat = useSelector(getDateFormat);
  const locale = useSelector(getLocale);
  const userData = useSelector(getUserData);
  const theme = useSelector(getTheme);

  useEffect(() => {
    dispatch(getLoansRowsFromServer(userData.level, employeeCode));
  }, [dispatch, userData, employeeCode])

  const createDataForLoansChart = (gridRef) => {
    let filteredRows = [];
    let loanValues, loanFee;

    let datosOK = true;

    gridRef.api.forEachNodeAfterFilter((row) => {
      if (!(row && row.data)) {
        datosOK = false;
      } else {
        filteredRows.push(row);
      }
    });

    if (datosOK && filteredRows.length > 0) {
      loanValues = {
        data: {
          "Total de Préstamos Otorgados": filteredRows.reduce(
            (a, b) => a + parseFloat(b.data["Valor total préstamos otorgados"]),
            0
          ),
          "Total Saldo Pendiente": filteredRows.reduce(
            (a, b) => a + parseFloat(b.data["Total saldo pendiente"]),
            0
          ),
          "Total Pagado": filteredRows.reduce((a, b) => a + parseFloat(b.data["Total pagado"]), 0),
        },
        title: "Valores de Préstamos",
      };

      loanFee = {
        data: {
          "Total Cuotas": filteredRows.reduce((a, b) => a + parseFloat(b.data["Total cuotas"]), 0),
          "Total Cuotas Pendientes": filteredRows.reduce(
            (a, b) => a + parseFloat(b.data["Total cuotas pendientes"]),
            0
          ),
          "Total Cuotas Pagadas": filteredRows.reduce(
            (a, b) => a + parseFloat(b.data["Total cuotas pagadas"]),
            0
          ),
        },
        title: "Cuotas de Préstamos",
      };
      return [loanValues, loanFee];
    } else {
      return null;
    }
  };

  const handleOpenGraphs = (gridRef) => {
    setGraphsDialogIsOpen(true);
    setDataForCharts(createDataForLoansChart(gridRef));
  };

  const handleRowClick = (row) => {
    dispatch(getLoansDetailRowsFromServer(userData.level, row.data["Tipo de préstamo"], row.data["Moneda"], employeeCode))
      .then(() => {
        setSelectedRow(row);
        setSecondGridDialogIsOpen(true);
      })
  };

  const graphicsItem = (gridRef) => loansRows.length > 0 &&
    <CustomIconButton
      title={getLocalizedString("charts")}
      onClick={() => handleOpenGraphs(gridRef)}
      type={"graphics"}
    />

  const goBackItem = () => Boolean(handleGoBack) &&
    <CustomIconButton
      title={'Volver'}
      onClick={handleGoBack}
      type={"goBack"}
    />

  return (
    <CommonPage
      rowData={loansRows}
      title={`${getLocalizedString("loansTitleLong")}${employeeName ? ` - ${employeeName}` : ''}`}
      gridTitle={`${getLocalizedString("loansTitleLong")}${employeeName ? ` - ${employeeName}` : ''}`}
      locale={locale}
      dateFormat={dateFormat}
      columnDefPage={paths.loans}
      handleRowClick={handleRowClick}
      isLoading={loadingRows || exportingGridStatus}
      hasHelp
      menuItems={[goBackItem, graphicsItem]}
      handleOpenGraphs={handleOpenGraphs}
      hasExpand
      hasSelectAll
    >
      {
        secondGridDialogIsOpen && !loadingRows && !exportingGridStatus &&
        <SecondGridDialog
          open={secondGridDialogIsOpen}
          data={loansRowsByTypeAndCurrency}
          type={selectedRow.data["Tipo de préstamo"]}
          coin={selectedRow.data["Moneda"]}
          level={userData.level}
          dateFormat={dateFormat}
          locale={locale}
          handleClose={() => setSecondGridDialogIsOpen(false)}
        />
      }

      {
        graphsDialogIsOpen &&
        <GraphsDialog
          open={graphsDialogIsOpen && loansRows.length > 0}
          handleClose={() => setGraphsDialogIsOpen(false)}
          data={dataForCharts}
          theme={theme}
          color={color}
        />
      }
    </CommonPage>
  );
}

export default Loans;
