import {
  GET_LOANS_ROWS,
  GET_LOANS_ROWS_FULFILLED,
  GET_LOANS_ROWS_REJECTED,
  GET_LOANS_DETAIL,
  GET_LOANS_DETAIL_FULFILLED,
  GET_LOANS_DETAIL_REJECTED,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getMyCurrentAccountLoansResumeApi,
  getEmployeesCurrentAccountLoansResumeApi,
  getMyCurrentAccountLoansByTypeAndCurrencyApi,
  getEmployeesCurrentAccountLoansByTypeAndCurrencyApi,
} from "@icarius-connection/api";
import { getLocalizedErrorString } from "@icarius-localization/strings";

export const getLoansDetailRowsFromServer = (level, type, currency, code) => async (dispatch) => {
  dispatch({ type: GET_LOANS_DETAIL });
  try {
    let query = `?type=${type}&currency=${currency}${code ? `&code=${code}` : ''}`;
    let response;

    if (level === "C") {
      response = await getMyCurrentAccountLoansByTypeAndCurrencyApi(query);
    } else if (level === "E") {
      response = await getEmployeesCurrentAccountLoansByTypeAndCurrencyApi(query);
    }

    let result = response && response.data && response.data.result;
    let date_format = response.data.date_format;
    let currency_localization = response.data.currency_localization;

    if (result.length <= 0) {
      dispatch({ type: GET_LOANS_DETAIL_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("noLoansFound"), severity: "error" }));
      return;
    }
    dispatch({
      type: GET_LOANS_DETAIL_FULFILLED,
      payload: {
        loans: result.map(resultado => {
          if (level === "E") {
            return {
              ...resultado,
              "Valor de la cuota": parseFloat(resultado["Valor de la cuota"]),
              "Valor del préstamo otorgado": parseFloat(resultado["Valor del préstamo otorgado"]),
              "MONEDA": resultado["Moneda"],
              "CODIGO DE EMPLEADO": resultado["Código de empleado"],
              "APELLIDO Y NOMBRE": resultado["Apellido y nombres"],
            }
          }

          return {
            ...resultado,
            "Valor de la cuota": parseFloat(resultado["Valor de la cuota"]),
            "MONEDA": resultado["Moneda"],
            "CODIGO DE EMPLEADO": resultado["Código de empleado"],
            "APELLIDO Y NOMBRE": resultado["Apellido y nombres"],
          };
        }),
        locale: currency_localization,
        dateFormat: date_format,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: GET_LOANS_DETAIL_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getLoansRowsFromServer = (level, code) => async (dispatch) => {
  dispatch({ type: GET_LOANS_ROWS });
  try {
    let response;
    if (level === "C") {
      response = await getMyCurrentAccountLoansResumeApi();
    } else if (level === "E") {
      response = await getEmployeesCurrentAccountLoansResumeApi(code ? `?code=${code}` : '');
    }

    let result = (response && response.data && response.data.result) || [];
    let date_format = response.data.date_format;
    let currency_localization = response.data.currency_localization;
    let decimal_quantity = response.data.decimal_quantity;

    dispatch({
      type: GET_LOANS_ROWS_FULFILLED,
      payload: {
        loans: result.map(resultado => ({
          ...resultado,
          "Total pagado": parseFloat(resultado["Total pagado"]),
          "Total saldo pendiente": parseFloat(resultado["Total saldo pendiente"]),
          "Valor total préstamos otorgados": parseFloat(resultado["Valor total préstamos otorgados"]),
          "MONEDA": resultado["Moneda"],
        })),
        locale: currency_localization,
        dateFormat: date_format,        
        decimalQuantity: decimal_quantity,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: GET_LOANS_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};