import {
  GET_LOANS_ROWS,
  GET_LOANS_ROWS_FULFILLED,
  GET_LOANS_ROWS_REJECTED,
  GET_LOANS_DETAIL,
  GET_LOANS_DETAIL_FULFILLED,
  GET_LOANS_DETAIL_REJECTED,
} from "./actionTypes";

const initialState = {
  loansRows: [],
  loansRowsByTypeAndCurrency: [],
  locale: "es",
  dateFormat: "yyyy/mm/dd",
  gettingLoansRows: false,
  decimalQuantity: 2,
  exporting: false,
  likeRequest: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    
    case GET_LOANS_ROWS:
      return { ...state, gettingLoansRows: true };
    case GET_LOANS_ROWS_FULFILLED:
      return {
        ...state,
        gettingLoansRows: false,
        loansRows: action.payload.loans,
        locale: action.payload.locale,
        dateFormat: action.payload.dateFormat,
        decimalQuantity: action.payload.decimalQuantity,
      };
    case GET_LOANS_ROWS_REJECTED:
      return { ...state, gettingLoansRows: false };

    case GET_LOANS_DETAIL:
      return { ...state, gettingLoansRows: true };
    case GET_LOANS_DETAIL_FULFILLED:
      return {
        ...state,
        gettingLoansRows: false,
        loansRowsByTypeAndCurrency: action.payload.loans,
        locale: action.payload.locale,
        dateFormat: action.payload.dateFormat,
      };
    case GET_LOANS_DETAIL_REJECTED:
      return { ...state, gettingLoansRows: false };

    default:
      return state;
  }
}
