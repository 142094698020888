import React from "react";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import Pie from "@icarius-pages/vacations/components/graphsDialog/pie";

const GraphsDialog = (props) => {

  const {
    open,
    data,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  if (!data) {
    handleClose();
    dispatch(openSnackbarAction({ msg: getLocalizedString("noDataToChart"), severity: "error", duration: 10000 }));
    return null;
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      scroll={"paper"}
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("charts")}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Grid container direction="row" justify="space-between" spacing={1}>
          {
            data.map((item, index) => {
              return (
                <Grid key={index} item xs={12} sm={6}>
                  <Card>
                    <CardContent>
                      <Pie
                        title={item.title}
                        list={Object.keys(item.data)}
                        data={Object.values(item.data)}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              );
            })
          }
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default GraphsDialog;